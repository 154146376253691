<template>
  <div>
    <b-form-group :label="formGroupLabel">
      <label
        class="checkbox checkbox-square"
        :class="
          (state != undefined
            ? state === true
              ? 'text-success'
              : 'text-danger'
            : '') + (formGroupLabel ? 'mt-3' : '')
        "
      >
        <input
          type="checkbox"
          v-model="value"
          :id="id"
          :name="name"
          :checked="inputValue()"
          :readonly="readonly"
        />
        <span class="mr-2"></span>
        <div v-html="label"></div>
        <slot name="content"></slot>
      </label>
      <b-form-invalid-feedback
        :id="`${id}-input-live-feedback`"
        v-if="errorMessage || (vError && vError.$error === true)"
      >
        <template v-if="errorMessage">
          {{ errorMessage }}
        </template>
        <template v-if="vError && vError.$error === true">
          <span v-if="vError.required === false || vError.sameAs === false">
            Bu alanı seçmeniz gerekmektedir.
          </span>
        </template>
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    formGroupLabel: {
      type: String,
      default: '',
    },
    label: String,
    id: String,
    name: String,
    state: { type: Boolean, default: undefined },
    readonly: { type: Boolean, default: false },
    defaultValue: { type: String, default: '' },
    errorMessage: { type: String, default: '' },
    vError: { type: Object, default: null },
  },
  name: 'Checkbox',
  data() {
    return {
      value: false,
    };
  },
  created() {
    this.setValue();
  },
  methods: {
    setValue() {
      this.value =
        this.defaultValue == 'true' || this.defaultValue == true ? true : false;
    },
    inputValue() {
      this.$emit('input', this.value);
    },
  },
  watch: {
    defaultValue(val) {
      this.setValue();
    },
  },
};
</script>
